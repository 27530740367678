<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between align-items-center">
      <div class="truncate">
        <b-card-sub-title class="mb-1">
          {{ design.text }}
        </b-card-sub-title>
        <span>{{ value }}</span>
      </div>
      <b-avatar
          :variant="`light-primary`"
          size="45"
      >
        <feather-icon
            size="21"
            :icon="design.icon"
        />
      </b-avatar>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "MiniStatisticChart",
  props: ['value', 'design']
}
</script>

<style scoped>

</style>