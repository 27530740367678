<template>
  <div class="count-table-container">
    <div v-for="key in Object.keys(counts)" :key="key" class="count-cell">
      <div class="header">{{ key }}</div>
      <div class="value">
        <b-badge class="w-100" variant="light-primary">
          {{ counts[key] }}
        </b-badge>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["counts"],
};
</script>

<style lang="scss">
.count-table-container {
  display: flex;
  flex-direction: column;
  border-radius: 0.35rem;
  overflow: clip;
  margin-bottom: 1rem;

  .header,
  .value {
    padding: 0.72rem 2rem;
    text-align: center;
  }
}

.count-cell {
  background-color: transparent;
  color: #b4b7bd;
  text-transform: uppercase;
  font-size: 0.857rem;
  letter-spacing: 0.5px;
  font-weight: bold;
  .header {
    background-color: #f3f2f7;
    border-top: 1px solid #ebe9f1;
    border-bottom: 2px solid #ebe9f1;
  }
}

.dark-layout .count-cell {
  background-color: #283046;
  border-color: #3b4253;
  .header {
    border-color: #3b4253;
    background-color: #343d55;
  }
}

.count-cell {
  display: flex;
  flex-direction: row;

  div {
    flex-shrink: 0;
    flex-basis: 50%;
  }
}

@media only screen and (min-width: 992px) {
  .count-cell {
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 20%;
  }

  .count-table-container {
    flex-direction: row;
    width: 100%;
  }
}
</style>