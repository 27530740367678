<template>
  <div class="small-stat-card">
    <b-avatar :variant="variant">
      <slot name="icon">
        <feather-icon :icon="icon" />
      </slot>
    </b-avatar>
    <div class="description">
      <span class="title">{{ value }}</span>
      <span class="text-muted subtitle">{{ label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: "Placeholder" },
    value: { default: 0 },
    icon: { type: String, default: "UserIcon" },
    variant: { type: String, default: "light-primary" },
  },
};
</script>

<style lang="scss" scoped>
.small-stat-card {
  display: flex;
  .b-avatar {
    margin: 3px 6px;
  }
  .description {
    height: 41px;
    padding: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
  }
  .title {
    line-height: 1.2rem;
  }
  .subtitle {
    font-size: 0.72rem;
    line-height: 0.8rem;
  }
}
</style>