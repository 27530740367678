<template>
  <div>
    <b-card>
      <b-row class="pb-1">
        <b-col sm="12" md="6">
          <b-card-title class="mb-25">
            {{ title }}
          </b-card-title>
        </b-col>
        <b-col sm="12" md="6">
          <div>
            <v-select
              :options="filters.groups"
              v-model="filter.group"
              :clearable="false"
              :searchable="false"
              class="select-size-sm mb-1"
            />
            <v-select
              :options="filters.statuses[type]"
              v-model="filter.status"
              :clearable="false"
              class="select-size-sm"
              :searchable="false"
            />
          </div>
        </b-col>
      </b-row>

      <div class="pb-0">
        <vue-apex-charts
          type="line"
          height="240"
          :options="options"
          :series="series"
        />
      </div>
    </b-card>
    <b-modal :id="id" centered>test</b-modal>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import VSelect from "vue-select";
import { parseISO, intlFormat } from "date-fns";

export default {
  name: "graph",
  components: { VueApexCharts, VSelect },
  props: {
    fetch: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "identification",
    },
  },
  computed: {
    options() {
      return {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: "line",
          dropShadow: {
            enabled: true,
            top: 18,
            left: 2,
            blur: 5,
            opacity: 0.2,
          },
          offsetX: -10,
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        grid: {
          borderColor: "#ebe9f1",
          padding: {
            top: -20,
            bottom: 5,
            left: 20,
          },
        },
        legend: {
          show: false,
        },
        colors: ["#df87f2"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            inverseColors: false,
            gradientToColors: [$themeColors.info],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            offsetY: 5,
            rotate: -45,
            rotateAlways: true,
            style: {
              colors: "#b9b9c3",
              fontSize: "0.857rem",
            },
          },
          axisTicks: {
            show: false,
          },
          categories: this.data.series.map((d) =>
            intlFormat(
              parseISO(d.date),
              {
                year: { year: "numeric" },
                month: { month: "long", year: "numeric" },
                day: { day: "numeric", month: "long" },
              }[this.data.meta.group],
              {
                locale: "en",
              }
            )
          ),
          axisBorder: {
            show: false,
          },
          tickPlacement: "on",
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            style: {
              colors: "#b9b9c3",
              fontSize: "0.857rem",
            },
            formatter: function (val, index) {
              return val.toFixed(0);
            },
          },
        },
        tooltip: {
          x: { show: false },
        },
      };
    },
    series() {
      return [
        {
          name: this.$t(this.type + ".status." + this.filter.status.value),
          data: this.data.series.map((d) => d.count),
        },
      ];
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.fetchData();
      },
    },
  },
  data() {
    return {
      id: "modal-" + Math.random() * 1000,
      filters: {
        groups: [
          { label: this.$t("common.filters.day"), value: "day" },
          { label: this.$t("common.filters.month"), value: "month" },
          { label: this.$t("common.filters.year"), value: "year" },
        ],
        statuses: {
          identification: [
            { label: this.$t("identification.status.match"), value: "match" },
            {
              label: this.$t("identification.status.no-match"),
              value: "no-match",
            },
            {
              label: this.$t("identification.status.no-face"),
              value: "no-face",
            },
            { label: this.$t("identification.status.failed"), value: "failed" },
          ],
          verification: [
            {
              label: this.$t("verification.status.completed"),
              value: "completed",
            },
            { label: this.$t("verification.status.failed"), value: "failed" },
            { label: this.$t("verification.status.no-face"), value: "no-face" },
          ],
        },
      },
      filter: {
        group: { label: this.$t("common.filters.day"), value: "day" },
        status: {
          label:
            this.type == "identification"
              ? this.$t("identification.status.match")
              : this.$t("verification.status.completed"),
          value: this.type == "identification" ? "match" : "completed",
        },
      },
      data: { series: [], meta: { group: "day" } },
    };
  },
  methods: {
    async fetchData() {
      const { data } = await this.fetch({
        group: this.filter.group.value,
        status: this.filter.status.value,
      });

      this.data.series = data.series;
      this.data.meta.group = data.meta.group;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>