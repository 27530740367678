<template>
  <b-card
      v-if="plan"
      class="earnings-card"
  >
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1">
          {{ plan.title }}
        </b-card-title>
        <div>
          <div class="font-small-4 mt-2">
            Total Organization
          </div>
          <h5 class="mb-1">
            {{ total }}
          </h5>
        </div>
      </b-col>
      <b-col cols="6">
        <vue-apex-charts
            v-if="series"
            height="120"
            :options="planChart.chartOptions"
            :series="series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: "PlanCard",
  props: {
    plan: {
      default: null
    }
  },
  components: {
    VueApexCharts
  },
  computed: {
    total() {
      return this.series?.reduce((pre,curr)=>pre+curr,0)
    }
  },
  data() {
    return {
      series: null,
      planChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          stroke: { width: 0 },
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },
  created() {
    this.series = Object.values(this.plan.counts)
    this.planChart.chartOptions.labels = Object.keys(this.plan.counts)
  }
}
</script>

<style scoped>

</style>