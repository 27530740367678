<template>
  <div>
    <div class="mb-1" v-for="(entry, index) in series" :key="index">
      <label class="d-flex justify-content-between">
        <span>{{ entry.title }}</span>
        <span>
          {{ entry.label }}
        </span>
      </label>
      <b-progress max="100" class="progress-bar-info">
        <b-progress-bar :value="entry.value" :variant="entry.variant" />
      </b-progress>
    </div>
  </div>
</template>

<script>
export default {
  name: "limits",
  props: ["limits"],
  computed: {
    series() {
      return [
        {
          title: this.$t("stat.title.user"),
          value: (
            (this.limits.user.count / this.limits.user.limit) *
            100
          ).toFixed(1),
          label: `${this.limits.user.count} / ${this.limits.user.limit}`,
          variant: "primary",
        },
        {
          title: this.$t("stat.title.person"),
          value: (
            (this.limits.person.count / this.limits.person.limit) *
            100
          ).toFixed(1),
          label: `${this.limits.person.count} / ${this.limits.person.limit}`,
          variant: "warning",
        },
        {
          title: this.$t("stat.title.identification"),
          value: (
            (this.limits.query.count / this.limits.query.limit) *
            100
          ).toFixed(1),
          label: `${this.limits.query.count} / ${this.limits.query.limit}`,
          variant: "danger",
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>