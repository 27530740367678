<template>
  <component :is="dashboard"/>
</template>

<script>
import OrganizationDashboard from "./OrganizationDashboard.vue";
import ServiceDashboard from "./ServiceDashboard.vue";

export default {
  components: {
    OrganizationDashboard,
    ServiceDashboard,
  },
  data() {
    return {
      dashboard: null
    }
  },
  created() {
    this.$auth.load().then(() => {
      const user = this.$auth.user()
      switch (user.guard_name) {
        case ("organization"):
          this.dashboard = "organization-dashboard"
          break
        case ("management"):
          this.dashboard = "service-dashboard"
      }
    })
  }
};
</script>

<style>
</style>