import axios from "@/libs/axios";


export async function aggregate(query) {
    try {
        const { data } = await axios.get("/api/v1/stats/aggregate", { params: query })
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function identifications(query) {
    try {
        const { data } = await axios.get("/api/v1/stats/identifications", { params: query })
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function verifications(query) {
    try {
        const { data } = await axios.get("/api/v1/stats/verifications", { params: query })
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function dashboard(query) {
    try {
        const { data } = await axios.get("/api/v1/stats/dashboard", { params: query })
        return { data }
    } catch (error) {
        return { error }
    }
}
