<template>
  <b-row>
    <b-col sm="6" md="3">
      <statistic-card-horizontal
        :statistic-title="$t('stat.title.user')"
        :statistic="counts.user"
        color="primary"
        icon="UserIcon"
      />
    </b-col>
    <b-col sm="6" md="3">
      <statistic-card-horizontal
        :statistic-title="$t('stat.title.photo')"
        :statistic="counts.photo"
        color="info"
        icon="ImageIcon"
      />
    </b-col>
    <b-col sm="6" md="3">
      <statistic-card-horizontal
        :statistic-title="$t('stat.title.person')"
        :statistic="counts.person"
        color="success"
        icon="UsersIcon"
      />
    </b-col>
    <b-col sm="6" md="3">
      <statistic-card-horizontal
        :statistic-title="$t('stat.title.identification')"
        :statistic="counts.query"
        color="warning"
        icon="SearchIcon"
      />
    </b-col>
  </b-row>
</template>

<script>
import { aggregate } from "@/api/stats.api";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";

export default {
  name: "aggregate-views",
  props: ["organization_id"],
  components: { StatisticCardHorizontal },
  data() {
    return {
      loading: false,
      counts: {
        user: 0,
        photo: 0,
        person: 0,
        query: 0,
      },
    };
  },
  watch: {
    organization_id() {
      this.refreshData();
    },
  },
  methods: {
    async refreshData() {
      const {
        data: { counts },
      } = await aggregate({ organization_id: this.organization_id });

      Object.keys(this.counts).forEach(
        (key) => (this.counts[key] = counts[key])
      );
    },
  },
  mounted() {
    this.refreshData();
  },
};
</script>

<style>
</style>